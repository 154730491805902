import React from 'react';
import { FormRenderData, Module } from '@cuatroochenta/co-admin-react-library';

import '@cuatroochenta/co-admin-react-library/lib/index.css';

interface FiltersType {
    [dynamic: string]: string
}

class App extends React.Component {

    private beforeFormView = (entity: string, type: string, data: FormRenderData): React.ReactNode => {
        return null;
    }

    private afterFormView = (entity: string, type: string, data: FormRenderData): React.ReactNode => {
        return null;
    }

    private customButtonsList = (entity: string, filters: FiltersType, orderby: string, order: string): React.ReactNode => {
        return null;
    };

    public render() {
        return (
            <Module
                actions={[]}
                beforeFormView={this.beforeFormView}
                afterFormView={this.afterFormView}
                customButtonsList={this.customButtonsList}
                locale={'es'}
            />
        );
    }

}

export default App;
